@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.dropdownArrow::after {
  background: linear-gradient(135deg, white 0%, white 45%, transparent 45%, transparent 100%);
}

.rdp-chevron {
  fill: #23B4B5;
}

.rdp-rotate-chevron .rdp-chevron {
  transform: rotate(180deg);
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter-Regular.ttf");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}